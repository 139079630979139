import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from "../components/layout/Layout"
import Seo from "../components/layout/Seo"

const Post = styled.article`
  margin: 64px auto;
  max-width: 1200px;
  padding: 0 12px;
  a {
    color: ${({ theme }) => theme.darkBlue};
    text-decoration: underline;
    transition: color 0.2s ease-in-out;
    :hover {
      color: ${({ theme }) => theme.blue};
    }
  }
  > * {
    color: ${({ theme }) => theme.lightGrey};
  }
`

const Title = styled.h3`
  margin: 16px 0;
  color: ${({ theme }) => theme.dark};
  text-transform: capitalize;
`

const Date = styled.p`
  font-size: 0.875rem;
  line-height: 1.5em;
  margin: 0;
  color: ${({ theme }) => theme.lightGrey};
  font-weight: 500;
`

export default function BlogPostTemplate({ data }) {
  const {
    mdx: {
      frontmatter: { title, date, cover },
      body,
      excerpt,
    },
  } = data

  return (
    <Layout path="/blog/" darkText>
      <Seo
        title={title}
        description={excerpt}
        image={cover.childImageSharp.ogImage}
      />
      <Post>
        <GatsbyImage
          image={getImage(cover)}
          alt={title}
          css={css`
            height: 46.66vw;
            max-height: 600px;
            min-height: 175px;

            @media (min-width: 768px) {
              border-radius: 32px;
              overflow: hidden;
            }
          `}
        />

        <Title>{title}</Title>

        <Date>{date}</Date>

        <MDXRenderer>{body}</MDXRenderer>
      </Post>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        cover {
          childImageSharp {
            gatsbyImageData(
              webpOptions: { quality: 70 }
              layout: FULL_WIDTH
              quality: 80
              placeholder: DOMINANT_COLOR
            )
            ogImage: resize(
              fit: COVER
              height: 630
              width: 1200
              quality: 90
              toFormat: JPG
            ) {
              src
              width
              height
            }
          }
        }
      }
      body
      excerpt
    }
  }
`
